@use "sass:map";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../variables";
@import "../patterns";
@import "../heights";

#my-work {
    .work-card {
        &:hover {
            transform: translateY(-1%);
            box-shadow: 0 0 15px #000000;
        }
    }
}

#reviews {

    #reviews-swiper {
        max-width: 500px;
    }
    .swiper-slide{
        border-radius: 10px;
        overflow: visible;
    }

    .swiper-pagination .swiper-pagination-bullet-active {
        background-color: $purple;
    }
}

#contact-me {
    .icon .fab {
        transition: 0.5s;

        &:hover {
            transform: rotate(15deg);
        }
    }
}

.arrow_box {
    position: absolute;
    border: 2px solid $dark-purple;
    padding: 0.5rem;
    width: 250px;
    text-align: center; border-radius: 10px;
    max-width: 40vw;

    svg {
        position: absolute;
        top: 100%;
        right: 0;
        width: 50px;
        height: 50px;
    }
}


#landing {
    width: stretch; height:stretch;
    position: fixed; top: 0; left: 0;
    background: linear-gradient(0deg,$background,rgba(0,0,0,0)); overflow: hidden;
}

#backgroundSVG {
    width: 100%; height: 100%;
    position: absolute; top: 0; left: 0; pointer-events: none;
}

#hero {
    position: absolute; top: 0; left: 0;
    width: $vw-full; height: 100vh; z-index: 1;
}

#labelRenderer {
    position: absolute; top: 0; left: 0;
    width: $vw-full; height: 100vh; pointer-events: none;
}


#the-clock {
    position: absolute; top: 20vh; left: 10vw;
    height: 10vh;
}

#the-frame {
    position: absolute; top: 15vh; right: 10vw;
    height: 15vh;
}

#mobile-scroll {
    bottom: 2vh;
    top: auto;
    color: $light;
    z-index: 1;
    cursor: pointer;

    i {
        margin-top: -0.7rem;
    }
}

@media (min-width: map.get($grid-breakpoints,'lg')){
    #the-frame {
        height: 20vh;
    }

    #hero {
        z-index: 0;
    }
}
